<template>
  <bs-popup id="mapping-aircraft-popup" @on-popup-closed="onPopupClosed" :auto-show="true" ref="form">
    <template v-slot:title>Form Mapping SP-SH-Price</template>
    <template v-slot:content>
      <bs-error-message :errors="errorMessages"/>

      <div class="mb-3">
        <label for="contractId" class="form-label">No. Contract</label>
        <contract-combo v-model="model.contractId" :textDefault="model.contractNumber" @returnItemSelected="setSelectedContract"/>
        <bs-input-error :errors="errorMessages" keyError="Contract" />
      </div>

      <div class="mb-3" v-if="model.contractId && model.soldToPartyLabel">
        <label for="code" class="form-label">Sold to</label>
        <bs-plain-text v-model="model.soldToPartyLabel"/>
      </div>

      <div class="mb-3">
        <label for="shipToId" class="form-label">Ship to</label>
        <customer-combo v-model="model.shipToId" :textDefault="model.shipToPartyLabel" />
        <bs-input-error :errors="errorMessages" keyError="ShipTo" />
      </div>

      <div class="mb-3">
        <label for="locationId" class="form-label">Location</label>
        <location-combo v-model="model.locationId" :textDefault="model.locationCode"/>
      </div>

      <div class="mb-3">
        <label for="basePriceId" class="form-label">Base Price</label>
        <base-price-combo v-model="model.basePriceId" :textDefault="model.basePriceLabel" @returnItemSelected="setSelectedBasePrice"/>
      </div>

      <div class="mb-3" v-if="model.basePriceId && model.basePriceQuantity">
        <label for="code" class="form-label">Price</label>
        <bs-plain-text v-model="model.basePriceQuantity"/>
      </div>

      <div class="mb-3">
        <label for="diffrential" class="form-label">Diffrential</label>
        <bs-textbox type="number" v-model="model.diffrential"/>
      </div>

      <div class="mb-3">
        <label for="feeProfileId" class="form-label">Fee</label>
        <fee-profile-combo v-model="model.feeProfileId" :textDefault="model.feeProfileName"/>
      </div>

      <div class="mb-3">
        <label for="currencyId" class="form-label">Sales Currency</label>
        <currency-combo v-model="model.currencyId" :textDefault="model.currencyCode"/>
      </div>

      <div class="mb-3">
        <label for="unitId" class="form-label">Sales Unit</label>
        <unit-combo v-model="model.unitId" :textDefault="model.unitCode"/>
      </div>

      <div class="mb-3">
        <label for="TermsOfPaymentId" class="form-label">Terms of Payment</label>
        <terms-of-payment-combo v-model="model.termsOfPaymentId" :textDefault="model.termsOfPaymentName"/>
      </div>

    </template>
    <template v-slot:footer>
      <button class="btn btn-sm btn-primary" @click="saveData"><i class="bi bi-check-circle"></i> Save</button>
      <button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
    </template>
  </bs-popup>
</template>

<script>
import SpshService from "@/services/spsh-service";
import CustomerCombo from "@/components/CustomerCombo.vue";
import ContractCombo from "@/components/ContractCombo.vue";
import TermsOfPaymentCombo from "@/components/TermsOfPaymentCombo.vue";
import LocationCombo from "@/components/LocationCombo.vue";
import CurrencyCombo from "@/components/CurrencyCombo.vue";
import UnitCombo from "@/components/UnitCombo.vue";
import BasePriceCombo from "@/components/BasePriceCombo.vue";
import FeeProfileCombo from "@/components/FeeProfileCombo.vue";
// import SecurityService from "@/services/security-service.js";

export default {
  components: {
    FeeProfileCombo,
    BasePriceCombo,
    UnitCombo, CurrencyCombo, LocationCombo, TermsOfPaymentCombo, ContractCombo, CustomerCombo},
  data: function () {
    return {
      id: null,
      model: SpshService.default(),
      errorMessages: null
    }
  },
  mounted() {
    let id = this.$route.params.id;
    if (id !== undefined && id !== null && id !== 0) {
      this.id = id;
      this.loadData();
    }
  },
  async loadData() {
    if (this.id === undefined || this.id === null || this.id === 0) {
      return;
    }

    const result = await SpshService.getObject(this.id);
    if (result.status) {
      this.errorMessages = result.errorMessages;
    } else {
      this.errorMessages = null;
    }

    this.model = result.data;
  },
  methods: {
    async loadData() {
      let response = await SpshService.getObject(this.id);
      this.model = response.data;
      if (response.status) {
        this.errorMessages = null;
      } else {
        this.errorMessages = response.errorMessages;
      }
    },
    onPopupClosed() {
      this.$router.push('/spsh');
    },
    close() {
      this.$refs.form.closePopup();
    },
    setSelectedContract(item){
      this.model.soldToPartyLabel = item.soldToLabel;
    },
    setSelectedBasePrice(item){
      this.model.basePriceQuantity = item.quantity;
    },
    async saveData() {
      try {
        this.$store.commit('progressCounterQueueIncrement');
        let status = false;
        if (this.id !== undefined && this.id !== null && this.id !== 0) {
          const result = await SpshService.update(this.model, this.id);
          status = result.status;
          if (status) {
            this.errorMessages = null;
          } else {
            this.errorMessages = result.errorMessages;
          }
        } else {
          const result = await SpshService.create(this.model);
          status = result.status;
          console.log('save status', status);
          if (status) {
            this.errorMessages = null;
            this.id = result.data.id;
          } else {
            this.errorMessages = result.errorMessages;
          }
        }

        if (!status) return;

        this.$refs.form.closePopup();

        this.$router.push(`/spsh/detail/${this.id}`);


      } finally {
        this.$store.commit('progressCounterQueueDecrement');
      }
    }
  }
}
</script>